<template>
    <div>
        <navheader></navheader>
        <div class="liveBox">
            <div class="className">

            </div>
            <div class="liveContentBox">
                <div id="mse"></div>
                <div id="chat">
                    <div class="mainBox">
                        <div class="tabBox">
                            <el-tabs v-model="activeName">
                                <el-tab-pane label="讨论" name="first" :key="'first'">
                                    <div id="msg-box">
                                        <div class="text" v-for="(item,index) in messageList" :key="index">
                                            <div class="username">{{item.userName}}：</div>
                                            <div class="usertext">{{item.msg}}</div>
                                        </div>
                                        <div v-if="this.speechInfo == 'send_fail'">
                                            <p style="font-size: 14px;text-align: center;color: white;">禁言中</p>
                                        </div>
                                        <div v-if="this.speechInfo == 'chat_forbidden'">
                                            <p style="font-size: 14px;text-align: center;color: white;">您已被管理员禁言</p>
                                        </div>
                                        <div v-if="this.speechInfo == 'chat_allow'">
                                            <p style="font-size: 14px;text-align: center;color: white;">您已被管理员解除禁言</p>
                                        </div>
                                    </div>
                                </el-tab-pane>
                            </el-tabs>
                        </div>
                    </div>
                    <div class="inputBox">
                        <div class="inputCheck">
                            <textarea type="text" class="wbInput" v-model="wbInput" placeholder="请友善进行交流" @keyup.enter="sendMessage"></textarea>
                            <div class="sendButton" @click="sendMessage">
                                <p>发送</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <navfooter></navfooter>
    </div>
</template>
<script>
import navheader from '../components/NavHeader'
import navfooter from '../components/NavFooter'
import 'xgplayer';
import FlvPlayer from 'xgplayer-flv';
export default {
    data() {
        return {
            wbInput: "",
            messageList: [],
            speechInfo: '',
            messageHistoryList: [],
            activeName: "first",
            liveId: this.$route.query.liveId,
            Player: null, //播放器初始
            playVideo: {},
            websockUrl: '',
            resourceList: [],
            playerConfig: { // 西瓜播放器配置
                id: "mse",
                isLive: true, // 直播模式
                autoplay: true,
                // hasVideo: true,
                // fluid: true,
                controls: true,
                width: '1161px',
                height: '783px',
                cors: true, // 跨域
                url: null // 直播地址
            },
            url1: '', // 清晰度1
            url2: '', // 清晰度2
            url3: '', // 清晰度3
            url4: '', // 清晰度4

            lockReconnect: false, //避免重复连接
            timeout: 40*1000,//40秒一次心跳
            timeoutObj: null,//心跳心跳倒计时
            serverTimeoutObj: null,//心跳倒计时
            timeoutnum: null,//断开 重连倒计时

        }
    },
    metaInfo: {
        title: '直播播放-网校', // set a title
        meta: [
            {             // set meta
                name: 'keyWords',
                content: '我是contact关键字'
            },
            {
                name: 'description',
                content: '我是contact描述'
            }
        ]
    },
    components: {
        navheader,
        navfooter,
    },
    mounted() {
        this.getLive();
    },
    // created() { 
	// 	window.addEventListener('beforeunload', e => this.websocketclose(e))
	// },
    beforeDestroy() { 
        //卸载事件
        // window.removeEventListener('beforeunload', e => this.websocketclose(e))
        this.websocketclose();
    },
    methods: {
        initWebSocket() {                
			// WebSocket与普通的请求所用协议有所不同，ws等同于http，wss等同于https                
			this.websock = new WebSocket(this.websockUrl); //这里是websocket服务地址，这里的地址可以前端根据后台地址参数规则拼成，也可以向后端请求         
			this.websock.onopen = this.websocketonopen;                
			this.websock.onerror = this.websocketonerror;                
			this.websock.onmessage = this.websocketonmessage;                
			this.websock.onclose = this.websocketclose;              
		},
        sendWebsocket(msg) {
            this.websock.send(msg)
        },
		websocketonopen() {
            //开启心跳
            this.start();
		},              
		websocketonerror() {                
			//重连
            this.reconnect();           
		},              
        websocketonmessage(e) {
            if(e.data == '#PONG#'){
                // console.log("hhh")
            } else {
                let message = JSON.parse(e.data);
                this.speechInfo = message.msg;
                if(message.chatMsgType == "SYSTEM"){
                    // console.log("eee")
                }
                this.$nextTick(function(){
                    let div = document.getElementById("msg-box");
                    div.scrollTop = div.scrollHeight
                })
                this.getHistory();
            }
            this.reset();
        },
		websocketclose() {                
			//重连
            // this.reconnect();
            // if(this.websock){
            //     this.websock.onclose();
            //     let _this= this
            //     _this.ws.onclose = function() {
            //         console.log("websocket已关闭"); 
            //     };
            // }          
		},
        sendMessage() {
            this.sendWebsocket(this.wbInput)
            this.wbInput = '';
        },
        reconnect() {//重新连接
            let that = this;
            if(that.lockReconnect) {
                return;
            }
            that.lockReconnect = true;
            //没连接上会一直重连，设置延迟避免请求过多
            that.timeoutnum && clearTimeout(that.timeoutnum);
            that.timeoutnum = setTimeout(function () {
                //新连接
                that.initWebSocket();
                that.lockReconnect = false;
            },10000);
        },
        reset(){//重置心跳
            let that = this;
            //清除时间
            clearTimeout(that.timeoutObj);
            clearTimeout(that.serverTimeoutObj);
            //重启心跳
            that.start();
        },
        start(){ //开启心跳
            let self = this;
            self.timeoutObj && clearTimeout(self.timeoutObj);
            self.serverTimeoutObj && clearTimeout(self.serverTimeoutObj);
            self.timeoutObj = setTimeout(function(){
                //这里发送一个心跳，后端收到后，返回一个心跳消息，
                if (self.websock.readyState == 1) {//如果连接正常
                    self.websock.send("#PING#"); //这里可以自己跟后端约定
                }else{//否则重连
                    self.reconnect();
                }
                self.serverTimeoutObj = setTimeout(function() {
                //超时关闭
                self.websock.onclose();
                }, self.timeout);
            }, self.timeout)
        },
        onopen() {
            //开启心跳
            this.start();
        },
        // 获取直播间历史聊天信息
        getHistory() {
            this.axios({
                method: 'POST',
                url: 'api/v2/yun/live/live_chat_history.html',
                data: {
                    liveId: this.liveId,
                },
            }).then((res) => {
                this.messageList = res.data.result.chatMsgs;
            })
        },
        // 获取直播间信息
        getLive() {
            this.axios({
                method: 'POST',
                url: 'api/v2/yun/live/play.html',
                data: {
                    liveId: this.liveId,
                },
            }).then((res) => {
                this.playVideo = res.data.result.liveInfo;
                this.websockUrl = res.data.result.chatInfo.webSocketUrl;
                this.liveSource();
                this.initWebSocket(); //进入页面开始初始化websocket建立连接 
            })
        },
        // 直播源
        liveSource() {
            var that = this;
            that.axios({
                method: 'POST',
                url: 'api/v2/yun/live/url.html',
                data: {
                    liveId: that.liveId,
                    key: that.playVideo.key,
                    streamType: that.playVideo.streamType
                },
            }).then((res) => {
                that.playerConfig.url = res.data.result.url;
                for(let i = 0;i<res.data.result.lines.length;i++) {
                    // console.log(that.resourceList[i]);
                    // let changes = res.data.result.lines[0].dataList;
                    let changes = res.data.result.lines;
                    that.resourceList = changes;
                    // console.log(that.resourceList)
                    break
                }
                // console.log(that.resourceList.url,"视频源")
                // if(that.resourceList.length != 0) {
                
                // }
                for(let i = 0;i<that.resourceList.length;i++){
                    
                    for(let k = 0;k<that.resourceList[i].dataList.length;k++) {
                        that.url1 = that.resourceList[i].dataList[0].url;
                        that.url2 = that.resourceList[i].dataList[1].url;
                        that.url3 = that.resourceList[i].dataList[2].url;
                        // that.url4 = that.resourceList[i].dataList[3].url;
                        break;
                    }
                    
                }
                // if(that.resourceList[i].url.length > 1) {
                //         that.url1 = that.resourceList[i].url;
                //         break;
                //     } else {
                //         that.url1 = that.resourceList[3].url;
                //         that.url2 = that.resourceList[2].url;
                //         that.url3 = that.resourceList[1].url;
                //         that.url4 = that.resourceList[0].url;
                //         console.log(that.url1)
                //         break;
                //     }
                this.player = new FlvPlayer(this.playerConfig);
                this.player.emit('resourceReady', [{name: '流畅', url: that.url1}, {name: '超清', url: that.url2},{name: '蓝光4M', url: that.url3}]);
            })
        }
    }

}
</script>
<style lang="scss">
    .el-tabs__nav-wrap::after {
        background-color: #333333;
    }
    .liveBox {
        width: 100%;
        min-width: 1200px;
        padding-top: 70px;
        padding-bottom: 51.5px;
        box-sizing: border-box;
        background: #222222;
        .className {
            width: 100%;
            min-width: 1200px;
            height: 30px;
            background: #333333;
        }
        .liveContentBox {
            display: flex;
            width: 1500px;
            margin: 0 auto;
            #mse {
                width: 1160px;
                height: 783px;
            }
            #chat {
                width: 340px;
                height: 783px;
                background: #444444;
                .mainBox {
                    width: 100%;
                    height: 614px;
                    border-bottom: 1px solid #222222;
                    .tabBox {
                        width: 100%;
                        height: 59px;
                        .el-tabs__nav {
                            height: 59px;
                            line-height: 59px;
                            .el-tabs__active-bar {
                                background-color: #FA4B66;
                            }
                            .el-tabs__item {
                                padding: 0;
                                width: 170px;
                                text-align: center;
                                font-size: 18px;
                                font-family: Microsoft YaHei;
                                font-weight: 400;
                                color: #EEEEEE;
                            }
                        }
                        #msg-box {
                            overflow-y: auto;
                            height: 541px;
                            padding-left: 10px;
                            box-sizing: border-box;
                            .text {
                                display: flex;
                                font-size: 14px;
                                font-family: Microsoft YaHei;
                                font-weight: 400;
                                color: #fff;
                                line-height: 30px;
                            }
                        }
                        #msg-box::-webkit-scrollbar{
                            display: none;
                        }
                    }
                }
                .inputBox {
                    padding-left: 20px;
                    padding-top: 30px;
                    box-sizing: border-box;
                    .inputCheck {
                        // display: flex;
                        // align-items: center;
                        .wbInput {
                            width: 272px;
                            padding: 5px;
                            box-sizing: border-box;
                            resize: none;
                            height: 64px;
                            margin-left: 14px;
                        }
                        .sendButton {
                            width: 100px;
                            height: 40px;
                            background: #FA4B66;
                            border-radius: 2px;
                            margin-top: 11px;
                            line-height: 40px;
                            margin-left: 185px;
                            text-align: center;
                            font-size: 16px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            color: #EEEEEE;
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }
</style>